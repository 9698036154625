<h4 class="pb-6 text-center" >User Preferences</h4>

<div class="grid align-items-center card col-12 ml-0">
    <div class="col-6 lg:col-3 md:col-4 sm:col-6">
        <p-dropdown [(ngModel)]="orderEntryMonth" (ngModelChange)="applyGridFilter()" placeholder="Select Order Entry Months " [options]="orderEntryMonths" 
            optionLabel="monthsPrefCode" filterBy="monthsPrefCode" [showClear]="true" [filter]="true" [resetFilterOnHide]="true" 
            [loading]="loading.includes('OrderEntryMonthsPref')" [style]="{ width: '100%' }" [virtualScroll]="true" [virtualScrollItemSize]="38"
            appendTo="body"></p-dropdown>
    </div>
    <div class="col-6 lg:col-3 md:col-4 sm:col-6">
        <p-dropdown class="w-50" [(ngModel)]="timeZone" (ngModelChange)="applyGridFilter()" placeholder="Select a Time Zone" [showClear]="true" [options]="timeZones"
            optionLabel="timezoneShortName" [showClear]="true" [style]="{ width: '100%' }" [filter]="true" [loading]="loading.includes('timeZones')"
            filterBy="timezoneShortName" [resetFilterOnHide]="true" [virtualScroll]="true" [virtualScrollItemSize]="38"
            appendTo="body" >
        <i class="pi pi-info-circle"></i>
        </p-dropdown>
    </div>
    <div class="col-6 lg:col-6 md:col-4 sm:col-6 flex justify-content-center gap-3">
         <label class="font-semibold text-base" >
                All or Nothing
         </label>
        <p-inputSwitch [(ngModel)]="allOrNothingSlider"></p-inputSwitch>
    </div>
</div>

<p-tabView [(activeIndex)]="activeTabIndex">
    <p-tabPanel header="Clients">
        <app-client-user-preference *ngIf="activeTabIndex == 0"></app-client-user-preference>
    </p-tabPanel>
    <p-tabPanel header="Products">
        <app-product-user-preference *ngIf="activeTabIndex == 1"></app-product-user-preference>
    </p-tabPanel>
    <p-tabPanel header="Traders">
        <app-trader-user-preference *ngIf="activeTabIndex == 2"></app-trader-user-preference>
    </p-tabPanel>
</p-tabView>