<div class="grid">
    <div class="col-12 main-table-container">
        <h4 style="text-align: center;">Products</h4>
        <app-ref-data-table [createNewRecordLabel]="createNewRecordLabel" [tableData]="userPreferedProductData" [cols]="columns"
            (enableNewDialogModel)="enableUserPreferedProductDialog()" [loading]="loading.includes('userPreference')"
            (enableEditDialogModel)="enableEditUserPreferedProductDialog($event)"
            (activateDeactivate)="addUpdateUserPreferedProducts($event)">
        </app-ref-data-table>
    </div>
</div>

<p-dialog #DialogStep [header]="header" [(visible)]="showUserPreferedProductDialogForm"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{ width: '50vw' }" [modal]=true>
    <ng-template pTemplate="body">
        <div class="col-12">
            <app-ref-data-form-fields [form]="userPreferedProductForm" [cols]="columns"></app-ref-data-form-fields>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button class="p-button-danger p-button-sm m-2 p-button-raised" type="button" pButton icon="pi pi-times"
            label="CANCEL" (click)="showUserPreferedProductDialogForm = false"
            [disabled]="loading.includes('updateUserPrefernceProduct')"></button>
        <button class="p-button-success p-button-sm m-2 p-button-raised" pButton icon="pi pi-check" label="SAVE"
            [loading]="loading.includes('updateUserPrefernceProduct')" (click)="addUpdateUserPreferedProducts(userPreferedProductForm)"
            [disabled]="!userPreferedProductForm.valid"></button>
    </ng-template>
</p-dialog>