<div class="grid">
    <div class="col-12">
        <div class="card">
            <h5 class="text-center">UNA Allocations</h5>

            <div class="grid">
                <div class="col-12 lg:col-6 md:col-6 sm:col-12">
                    <p-dropdown [options]="unaClients" [(ngModel)]="selectedClient" optionLabel="name" 
                    placeholder="Select a Client" (onChange)="onUnaClientChange($event)" [loading]="loading.includes('unaClients')"
                    [virtualScroll]="true" [virtualScrollItemSize]="38" resetFilterOnHide="true"
                    [showClear]="true" [filter]="true" filterBy="clientAlias" appendTo="body"></p-dropdown>
                </div>
                <div class="col-12 lg:col-6 md:col-6 sm:col-12" *ngIf="!loading.includes('potentialOrderFillsTrades') && selectedClient">
                    <h6>Upload UNA File</h6>
                    <p-progressBar *ngIf="loading.includes('analyzeUnaDeals')" mode="indeterminate" [style]="{ height: '6px' }" />
                    <p-fileUpload #fileUpload
                        customUpload="true"
                        (uploadHandler)="onUpload($event)" 
                        [multiple]="false" 
                        accept=".csv" 
                        [auto]="true"
                        chooseLabel="Select File"
                        maxFileSize="1000000">
                            <ng-template pTemplate="content">
                                <ul *ngIf="uploadedFiles.length">
                                    <li *ngFor="let file of uploadedFiles">
                                        {{ file.name }} - {{ file.size }} bytes
                                    </li>
                                </ul>
                            </ng-template>
                    </p-fileUpload>
                </div>
            </div>



            <div class="grid mt-4">
                <div *ngIf="selectedClient" class="col-12 lg:col-6 md:col-6 sm:col-12">
                    <h6>Current Order Fills needing G/Us</h6>
                    <p-table [value]="potentialOrderFills" [loading]="loading.includes('potentialOrderFillsTrades')" styleClass="p-datatable-gridlines p-datatable-sm">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Order ID</th>
                                <th>Sentence</th>
                                <th>Manual Allocation</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-unaAllocation>
                            <tr>
                                <td>{{unaAllocation.orderId}}</td>
                                <td>{{unaAllocation.fillSentence}}</td>
                                <td><button pButton type="button" icon="pi pi-plus" class="p-button-success p-button-sm" (click)="startManualOrderFillGiveup(unaAllocation)"></button></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">No order fills found</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>

                <div *ngIf="selectedClient" class="col-12 lg:col-6 md:col-6 sm:col-12">
                    <h6>Current Trades needing G/Us</h6>
                    <p-table [value]="potentialTrades" [loading]="loading.includes('potentialOrderFillsTrades')" styleClass="p-datatable-gridlines p-datatable-sm">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Trade ID</th>
                                <th>Sentence</th>
                                <th>Manual Allocation</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-unaAllocation>
                            <tr>
                                <td>{{unaAllocation.id}}</td>
                                <td>{{unaAllocation.tradeSentence}}</td>
                                <td><button pButton type="button" icon="pi pi-plus" class="p-button-success p-button-sm" (click)="startManualTradeGiveup(unaAllocation)"></button></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">No trades found</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

            <hr>

            <div *ngIf="selectedClient && showStagedResults" class="grid mt-4">
                <div class="col-12">
                    <h6>Staged Result</h6>
                    <p-table [value]="stagedResults" [loading]="loading.includes('analyzeUnaDeals')" styleClass="p-datatable-gridlines p-datatable-sm"> 
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Trade/Order ID</th>
                                <th>Giveup Client</th>
                                <th>Giveup Account</th>
                                <th>Giveup Quantity</th>
                                <th>Sentence</th>
                                <th>Actions</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-result>
                            <tr>
                                <td>{{result.tradeOrderId}}</td>
                                <td [pEditableColumn]="result.giveupClientName" pEditableColumnField="giveupClientName">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [options]="result.allowedGiveupBrokers" optionLabel="giveupBrokerName" 
                                            placeholder="Select Giveup Broker" (onChange)="onStagedResultsGiveupBrokerChange($event, result)"
                                            resetFilterOnHide="true" [filter]="true" filterBy="giveupBrokerName" appendTo="body"></p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ result.giveupClientName }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td [pEditableColumn]="result.clientGiveupAccountName" pEditableColumnField="clientGiveupAccountName">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown [options]="result.allowedGiveupAccounts" optionLabel="giveupAccount" 
                                            placeholder="Select Giveup Account" [showClear]="true" (onChange)="onStagedResultsGiveupAccountChange($event, result)"
                                            resetFilterOnHide="true" [filter]="true" filterBy="giveupAccount" appendTo="body"></p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ result.clientGiveupAccountName }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td [pEditableColumn]="result.giveupQuantity" pEditableColumnField="giveupQuantity">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber 
                                            [(ngModel)]="result.giveupQuantity" min="1" showButtons="true" /> 
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ result.giveupQuantity }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>{{result.sentence}}</td>
                                <td>
                                    <button pButton type="button" icon="pi pi-minus" class="p-button-danger p-button-sm" pTooltip="Delete" (click)="deleteStagedAllocationRecord(result)"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="6">
                                    <button pButton type="button" label="Submit Allocations" class="p-button-success p-button-sm" [loading]="loading.includes('submitGiveupAllocations')"
                                    (click)="submitGiveupAllocations()" [disabled]="stagedResults.length == 0"></button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

        </div>
    </div>
</div>

<p-dialog [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [(visible)]="manualAllocation"
[breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{ width: '50vw' }" [modal]=true>
    <ng-template pTemplate="header">
        <b>Manual Allocation for {{manualOrderFill ? 'Order ' + manualOrderFill.orderId : 'Trade ' + manualTrade.id}}</b>
    </ng-template>
    <ng-template pTemplate="body">
        <div class="grid">
            <div class="col-12">
                {{manualOrderFill ? manualOrderFill.fillSentence : manualTrade.tradeSentence}}
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <p-dropdown *ngIf="manualOrderFill" (onChange)="onManualGiveupBrokerChange($event)" [options]="manualOrderFill.allowedGiveupBrokers" optionLabel="giveupBrokerName"
                    placeholder="Select Giveup Broker" [filter]="true" filterBy="giveupBrokerName" appendTo="body"></p-dropdown>

                <p-dropdown *ngIf="manualTrade" (onChange)="onManualGiveupBrokerChange($event)" [options]="manualTrade.allowedGiveupBrokers" optionLabel="giveupBrokerName"
                    placeholder="Select Giveup Broker" [filter]="true" [showClear]="true" filterBy="giveupBrokerName" appendTo="body"></p-dropdown>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <p-dropdown *ngIf="manualOrderFill" (onChange)="onManualGiveupAccountChange($event)" [options]="manualOrderFill.allowedGiveupAccounts" optionLabel="giveupAccount"
                    placeholder="Select Giveup Account" [filter]="true" filterBy="giveupAccountName" [showClear]="true" appendTo="body"></p-dropdown>
                    
                <p-dropdown *ngIf="manualTrade" (onChange)="onManualGiveupAccountChange($event)" [options]="manualTrade.allowedGiveupAccounts" optionLabel="giveupAccount"
                    placeholder="Select Giveup Account" [filter]="true" filterBy="giveupAccountName" [showClear]="true" appendTo="body"></p-dropdown>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <p-inputNumber [(ngModel)]="manualAllocationItem.giveupQuantity" placeholder="Giveup Quantity" min="1" showButtons="true" /> 
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button class="p-button-danger p-button-sm m-2 p-button-raised" type="button" pButton icon="pi pi-times"
            label="CANCEL" (click)="manualAllocation = false"
            [disabled]="loading.includes('updateUna')"></button>
        <button class="p-button-success p-button-sm m-2 p-button-raised" pButton icon="pi pi-check" label="ADD" 
            (click)="addManualAllocation(manualAllocationItem)" [disabled]="manualAllocationItem.giveupQuantity<= 0 || !manualAllocationItem.giveupClientId || manualAllocationItem.giveupClientId == 0"></button>
    </ng-template>
</p-dialog>
