import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { refDataGlobalModules } from 'src/app/constants/ref-data/global-modules';
import { Client } from 'src/app/models/client';
import { TableColumnType } from 'src/app/models/table-column-type';
import { UserPreferenceClient } from 'src/app/models/user-preferences-model';
import { UserProfile } from 'src/app/models/user-profile';
import { MomApiService } from 'src/app/service/mom-api.service';
import { RefDataFormFieldsComponent } from 'src/app/shared/ref-data-form-fields/ref-data-form-fields.component';
import { RefDataTableComponent } from 'src/app/shared/ref-data-table/ref-data-table.component';

@Component({
  selector: 'app-client-user-preference',
  standalone: true,
  imports: [...refDataGlobalModules, RefDataTableComponent, RefDataFormFieldsComponent],
  templateUrl: './client-user-preference.component.html',
  styleUrl: './client-user-preference.component.scss'
})
export class ClientUserPreferenceComponent {
  showUserPreferedClientDialogForm: boolean = false;
  enableUpdateUserPreferedClient: boolean = false;
  userPreferedClientId: string;
  header: string;
  createNewRecordLabel = "Add New Client";
  loading: string[] = new Array(); // Array of loading states for async operations

  // gentle note: where field indicates the object key name for value & header represents table header 
  // inputType indicates about the type of context rendering on UI
  columns: TableColumnType[];

  userPreferedClientForm = this.formBuilder.group({
    user : ['', Validators.required],
    client : ['', Validators.required],
    id: [0],
    active: [true],
    lastUpdatedByUserId: [1],
    lastUpdateTimestamp: [new Date()],
  });

  allClients: Client[];
  allUsers: UserProfile[];

  // userPreferedClientData test data
  userPreferedClientData: UserPreferenceClient[];

  constructor(private titleService: Title, private formBuilder: FormBuilder, private messageService: MessageService, private momApiService: MomApiService){}

  ngOnInit() {
    this.titleService.setTitle('Clients');

    this.columns = [
      { field: 'user', header: 'User', inputType: 'dropdown', entityPropertyName:"clientAlias", dropdownContent: this.allUsers },
      { field: 'client', header: 'Client', inputType: 'dropdown', entityPropertyName:"displayName", dropdownContent: this.allClients },
      { field: 'active', header: 'Active', inputType: 'slider', notes: 'Select edit icon to change active status' },
      { field: 'lastUpdatedByUserName', header: 'Updated By', inputType: 'text' },
      { field: 'lastUpdateTimestamp', header: 'Updated Date', inputType: 'dateTime' },
      { field: 'actionButtons', header: 'Actions', inputType: 'actionButtons', icons:["pi-pencil", "pi-trash"] },
    ];
    this.getProdutPortfolios();
    
    this.getClients();
    // this.getPortfolioClients();
  }

  getProdutPortfolios() {
    // API call to get Clients
    this.loading.push('userPreference');
    this.momApiService.getProdutPortfolios(true, false)
       .subscribe({
          next: (result: any) => {
             console.log(result);
             this.userPreferedClientData = result;
          },
          error: (error) => {
             
             this.messageService.add({ severity: 'error', summary: 'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred' });
             this.loading = this.loading.filter(item => item !== 'userPreference');
          },
          complete: () => {
             
             this.loading = this.loading.filter(item => item !== 'userPreference');
          }
       });
  }
 
//  //used to set the dropdowndata on dialog form
//  getPortfolioClients() {
//    this.momApiService.getPortfolioClients(false, true).subscribe({
//       next: (response: Client[]) => {
//         this.allUsers = response;
//         this.columns.forEach(col => {
//           if (col.field === "user") {
//             col.dropdownContent = response;
//           }
//         });
//       },
//       error: (error) => {
//         console.error('Error fetching user clients:', error);
//         this.messageService.add({severity:'error', summary:'Error', detail: 'Failed to load user clients.'});
//       }
//     });
//   }

getClients() {
  this.loading.push('clients');
  this.momApiService.getClients().subscribe({
    next: (result: any) => {
      this.allClients = result;
      this.columns.forEach(col => {
        if (col.field === "client") {
          col.dropdownContent = result;
        }
      });
    },
    error: (error) => {
      this.messageService.add({severity:'error', summary:'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred'});
      this.loading = this.loading.filter(item => item !== 'clients');
    },
    complete: () => {
      this.loading = this.loading.filter(item => item !== 'clients');
    }
  })
};

  addUpdateUserPreferedClients(produtPortfolioForm: FormGroup) {
    var produtPortfolio: any;
    var isFormGroup = produtPortfolioForm instanceof FormGroup;

    const clientId = this.allClients.filter(client => client.clientAlias === produtPortfolioForm.controls['client'].value)[0].id;
    // const portfolioId = this.allUsers.filter(user => user.clientLongName === produtPortfolioForm.controls['user'].value)[0].id;
    
    if (isFormGroup) {
      produtPortfolio = {
        id: produtPortfolioForm.controls['id'].value ?? 0,
        active: produtPortfolioForm.controls['active'].value ?? true,
        lastUpdatedByUserId: produtPortfolioForm.controls['lastUpdatedByUserId'].value ?? 0,
        lastUpdateTimestamp: produtPortfolioForm.controls['lastUpdateTimestamp'].value ?? new Date(),
        // portfolioId: portfolioId ?? 0,
        clientId: clientId ?? 0,
        user: null,
        client: null
      }
    } 
    else {
      // It's already a UserGroup or some other object
      produtPortfolio = produtPortfolioForm;
    }

    if (!isFormGroup || produtPortfolioForm.valid) {
      this.loading.push('updateUserPrefernceProduct');
      this.momApiService.postData('refdata/userPreferedClient', [produtPortfolio])
        .subscribe({
          next: (result: any) => {
            this.getProdutPortfolios();
          },
          error: (error) => {
            
            this.messageService.add({severity:'error', summary:'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred'});
            this.loading = this.loading.filter(item => item !== 'updateUserPrefernceProduct');
          },
          complete: () => {
            
            this.loading = this.loading.filter(item => item !== 'updateUserPrefernceProduct');
            if (produtPortfolio.id === 0) {
              this.messageService.add({severity:'success', key: 'toast', summary:'Success', detail: 'Client added successfully'});
            }
            else {
              this.messageService.add({severity:'success', key: 'toast', summary:'Success', detail: 'Client updated successfully.'});
            }

            this.showUserPreferedClientDialogForm = false;
          }
        });
    }
  }

  enableUserPreferedClientDialog(){
    this.showUserPreferedClientDialogForm = true;
    this.userPreferedClientForm.reset();
    this.userPreferedClientForm.controls['active'].setValue(true);
    this.header = 'Create Client';
  }

  enableEditUserPreferedClientDialog(userPreferedClient: UserPreferenceClient) {
    this.showUserPreferedClientDialogForm = true;
    
     // set userPreferedClient Form Values for update
     if (userPreferedClient.id && userPreferedClient) {
      this.header = 'Edit Portfolio Default';
      this.userPreferedClientId = `${userPreferedClient.id}`;
      this.enableUpdateUserPreferedClient = true;
      this.userPreferedClientForm.setValue({
        id: userPreferedClient.id,
        active: userPreferedClient.active,
        lastUpdatedByUserId: userPreferedClient.lastUpdatedByUserId,
        lastUpdateTimestamp: new Date(),
        client: userPreferedClient.client.clientAlias ?? null,
        // user: userPreferedClient.user.clientAlias ?? null
        user: null

      })
    }
  }

  resetUserPreferedClientDialogPostSubmit() {
    //clear the form post submission
    this.userPreferedClientForm.reset();
    this.enableUpdateUserPreferedClient = !this.enableUpdateUserPreferedClient;

    this.showUserPreferedClientDialogForm = false;
  }
}
