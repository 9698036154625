import { Component, ElementRef, ViewChild } from '@angular/core';
import { AppConfig, LayoutService } from './service/app.layout.service';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';
import { signOut, fetchUserAttributes } from 'aws-amplify/auth';
import { MomApiService } from '../service/mom-api.service';
import { MomWebSocketService } from '../service/websocket.service';
import { UserService } from '../service/user.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
})
export class AppTopbarComponent {
    @ViewChild('menuButton') menuButton!: ElementRef;

    @ViewChild('mobileMenuButton') mobileMenuButton!: ElementRef;

    scales: number[] = [12, 13, 14, 15, 16];
    config!: AppConfig;
    darkMode: boolean = false;
    subscription: any;
    userEmail: string = '';
    firstName: string = '';
    lastName: string = '';
    websocketConnected: boolean = false;

    constructor(public layoutService: LayoutService, public el: ElementRef, 
        public websocketService: MomWebSocketService, public userService: UserService, private momApiService: MomApiService,
        private messageService: MessageService) {
            
        this.subscription = this.layoutService.configUpdate$.subscribe(
            (config) => {
                this.config = config;
            }
        );
        let mode = localStorage.getItem('layout-mode');
        if (mode && mode === 'dark') {
            this.darkMode = true;
        }
    }

    async ngOnInit() {
        this.websocketService.isWebsocketConnected.subscribe((value) => {
            this.websocketConnected = value;
        })

        const userAttributes = await fetchUserAttributes();
        this.userEmail = userAttributes.email!;
        this.firstName = userAttributes.given_name!;
        this.lastName = userAttributes.family_name!;
        this.layoutService.applyDarkModeStyles();
    }

    get scale(): number {
        return this.layoutService.config().scale;
    }    
    set scale(_val: number) {
        this.layoutService.config.update((config) => ({
            ...config,
            scale: _val,
        }));
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onDarkModeChange(event: InputSwitchChangeEvent) {
        if(event.checked) {
            //This is dark mode
            this.layoutService.config.update((config) => ({
                ...config,
                colorScheme: 'dark',
                menuTheme: 'dark',
                topbarTheme: 'dark',
            }));
            localStorage.setItem('layout-mode', 'dark');
        }
        else {
            //This is light mode
            this.layoutService.config.update((config) => ({
                ...config,
                colorScheme: 'light',
                menuTheme: 'dim',
                topbarTheme: 'light',
            }));

            localStorage.setItem('layout-mode', 'light');
        }
        this.layoutService.applyDarkModeStyles();
    }

    decrementScale() {
        this.scale--;
    }

    incrementScale() {
        this.scale++;
    }

    async logOut() {
        await signOut();
    }

    refreshRefDataCache() {
        this.messageService.add({severity:'info', key: 'toast', summary:'Info', detail: 'Refreshing Ref Data Cache...'});
        this.momApiService.getData('refdata/refreshrefdata')
        .subscribe({
            next: (result: any) => {
                //This endpoint is just a trigger, we do not care about the result, only if it errors which is handled below
            },
            error: (error) => {
                // Handle error cases
                this.messageService.add({severity:'error', summary:'Error', detail: error.message});
            },
            complete: () => {
                // Handle completion cases
                this.messageService.add({severity:'success', key: 'toast', summary:'Success', detail: 'Refreshed Ref Data Cache'});
            }
        });
        
        //This takes the focus off of the menu and collapses it
        const element = document.querySelector('.layout-topbar-end') as HTMLElement;
        element?.click();
    }

    refreshBlotters() {
        console.log('Refresh Blotters');
        //put the api call here to trigger the refresh
    }

    refreshRfqData() {
        console.log('Refresh RFQ Data');
        //put the api call here to trigger the refresh
    }
}
