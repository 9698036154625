import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarTimezones } from '../models/calendar-timezones';
import { OrderEntryMonthsPref } from '../models/user-profile';
import { MomApiService } from '../service/mom-api.service';
import { ClientUserPreferenceComponent } from './client-user-preference/client-user-preference.component';
import { ProductUserPreferenceComponent } from './product-user-preference/product-user-preference.component';
import { TraderUserPreferenceComponent } from './trader-user-preference/trader-user-preference.component';

@Component({
  selector: 'app-user-preferences',
  standalone: true,
  imports: [TableModule,
    CommonModule,
    ButtonModule,
    TooltipModule,
    CheckboxModule,
    TabViewModule,
    InputTextModule,
    DialogModule,
    InputSwitchModule,
    DropdownModule,
    FormsModule, TraderUserPreferenceComponent, ClientUserPreferenceComponent, ProductUserPreferenceComponent],
  templateUrl: './user-preferences.component.html',
  styleUrl: './user-preferences.component.scss'
})
export class UserPreferencesComponent implements OnInit {
  activeTabIndex: number = 0;

  timeZone: string;
  timeZones: CalendarTimezones[];

  orderEntryMonth: string;
  orderEntryMonths: OrderEntryMonthsPref[] = [];

  allOrNothingSlider:boolean = false;
  loading: string[] = new Array(); // Array of loading states for async operations

  constructor(private messageService: MessageService,private momApiService: MomApiService){}

  ngOnInit() {
    this.getTimeZones();
    this.getOrderEntryMonthsPref();
  }

  getTimeZones() {
    this.loading.push('timeZones');
    this.momApiService.getTimezones().subscribe({
      next: (response: any) => {
        this.timeZones = response;
      },
      error: (error) => {
        this.loading = this.loading.filter(item => item !== 'timeZones');
        console.error('Error fetching Timezones:', error);
        this.messageService.add({severity:'error', summary:'Error', detail: 'Failed to load Timezones.'});
      },
      complete: () => {
        this.loading = this.loading.filter(item => item !== 'timeZones');
      }
    });
  }

  getOrderEntryMonthsPref() {
    this.loading.push('OrderEntryMonthsPref');
    this.momApiService.getOrderEntryMonthsPref().subscribe({
      next: (result: any) => {
        this.orderEntryMonths = result;
      },
      error: (error) => {
        this.messageService.add({severity:'error', summary:'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred'});
        this.loading = this.loading.filter(item => item !== 'OrderEntryMonthsPref');
      },
      complete: () => {
        
        this.loading = this.loading.filter(item => item !== 'OrderEntryMonthsPref');
      }
    })
  };

  applyGridFilter(){

  }
}
