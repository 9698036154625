import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { refDataGlobalModules } from 'src/app/constants/ref-data/global-modules';
import { TableColumnType } from 'src/app/models/table-column-type';
import { Trader } from 'src/app/models/trader';
import { UserPreferenceTrader } from 'src/app/models/user-preferences-model';
import { UserProfile } from 'src/app/models/user-profile';
import { MomApiService } from 'src/app/service/mom-api.service';
import { RefDataFormFieldsComponent } from 'src/app/shared/ref-data-form-fields/ref-data-form-fields.component';
import { RefDataTableComponent } from 'src/app/shared/ref-data-table/ref-data-table.component';

@Component({
  selector: 'app-trader-user-preference',
  standalone: true,
  imports: [...refDataGlobalModules, RefDataTableComponent, RefDataFormFieldsComponent],
  templateUrl: './trader-user-preference.component.html',
  styleUrl: './trader-user-preference.component.scss'
})
export class TraderUserPreferenceComponent {
  showUserPreferedTraderDialogForm: boolean = false;
  enableUpdateUserPreferedTrader: boolean = false;
  userPreferedTraderId: string;
  header: string;
  createNewRecordLabel = "Add New Trader";
  loading: string[] = new Array(); // Array of loading states for async operations

  // gentle note: where field indicates the object key name for value & header represents table header 
  // inputType indicates about the type of context rendering on UI
  columns: TableColumnType[];

  userPreferedTraderForm = this.formBuilder.group({
    user : ['', Validators.required],
    trader : ['', Validators.required],
    id: [0],
    active: [true],
    lastUpdatedByUserId: [1],
    lastUpdateTimestamp: [new Date()],
  });

  allTraders: Trader[];
  allUsers: UserProfile[];

  // userPreferedTraderData test data
  userPreferedTraderData: UserPreferenceTrader[];

  constructor(private titleService: Title, private formBuilder: FormBuilder, private messageService: MessageService, private momApiService: MomApiService){}

  ngOnInit() {
    this.titleService.setTitle('Traders');

    this.columns = [
      { field: 'user', header: 'User', inputType: 'dropdown', entityPropertyName:"clientAlias", dropdownContent: this.allUsers },
      { field: 'trader', header: 'Trader', inputType: 'dropdown', entityPropertyName:"fullName", dropdownContent: this.allTraders },
      { field: 'active', header: 'Active', inputType: 'slider', notes: 'Select edit icon to change active status' },
      { field: 'lastUpdatedByUserName', header: 'Updated By', inputType: 'text' },
      { field: 'lastUpdateTimestamp', header: 'Updated Date', inputType: 'dateTime' },
      { field: 'actionButtons', header: 'Actions', inputType: 'actionButtons', icons:["pi-pencil", "pi-trash"] },
    ];
    this.getProdutPortfolios();
    
    this.getTraders();
    // this.getPortfolioClients();
  }

  getProdutPortfolios() {
    // API call to get Traders
    this.loading.push('userPrefernceTrader');
    this.momApiService.getProdutPortfolios(true, false)
       .subscribe({
          next: (result: any) => {
             console.log(result);
             this.userPreferedTraderData = result;
          },
          error: (error) => {
             
             this.messageService.add({ severity: 'error', summary: 'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred' });
             this.loading = this.loading.filter(item => item !== 'userPrefernceTrader');
          },
          complete: () => {
             
             this.loading = this.loading.filter(item => item !== 'userPrefernceTrader');
          }
       });
 }
 
//  //used to set the dropdowndata on dialog form
//  getPortfolioClients() {
//    this.momApiService.getPortfolioClients(false, true).subscribe({
//       next: (response: Client[]) => {
//         this.allUsers = response;
//         this.columns.forEach(col => {
//           if (col.field === "user") {
//             col.dropdownContent = response;
//           }
//         });
//       },
//       error: (error) => {
//         console.error('Error fetching user clients:', error);
//         this.messageService.add({severity:'error', summary:'Error', detail: 'Failed to load user clients.'});
//       }
//     });
//   }

getTraders() {
  this.loading.push('traders');
  this.momApiService.getTraders(false, true).subscribe({
    next: (response: Trader[]) => {
      this.allTraders = response;
      this.columns.forEach(col => {
        if (col.field === "trader") {
          col.dropdownContent = response;
        }
      });
    },
    error: (error) => {
      this.messageService.add({severity:'error', summary:'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred'});
      this.loading = this.loading.filter(item => item !== 'traders');
    },
    complete: () => {
      this.loading = this.loading.filter(item => item !== 'traders');
    }
  });
}

  addUpdateUserPreferedTraders(produtPortfolioForm: FormGroup) {
    var produtPortfolio: any;
    var isFormGroup = produtPortfolioForm instanceof FormGroup;

    const traderId = this.allTraders.filter(trader => trader.fullName === produtPortfolioForm.controls['trader'].value)[0].id;
    // const portfolioId = this.allUsers.filter(user => user.clientLongName === produtPortfolioForm.controls['user'].value)[0].id;
    
    if (isFormGroup) {
      produtPortfolio = {
        id: produtPortfolioForm.controls['id'].value ?? 0,
        active: produtPortfolioForm.controls['active'].value ?? true,
        lastUpdatedByUserId: produtPortfolioForm.controls['lastUpdatedByUserId'].value ?? 0,
        lastUpdateTimestamp: produtPortfolioForm.controls['lastUpdateTimestamp'].value ?? new Date(),
        // portfolioId: portfolioId ?? 0,
        traderId: traderId ?? 0,
        user: null,
        trader: null
      }
    } 
    else {
      // It's already a UserGroup or some other object
      produtPortfolio = produtPortfolioForm;
    }

    if (!isFormGroup || produtPortfolioForm.valid) {
      this.loading.push('updateUserPrefernceTrader');
      this.momApiService.postData('refdata/userPreferenceProduct', [produtPortfolio])
        .subscribe({
          next: (result: any) => {
            this.getProdutPortfolios();
          },
          error: (error) => {
            
            this.messageService.add({severity:'error', summary:'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred'});
            this.loading = this.loading.filter(item => item !== 'updateUserPrefernceTrader');
          },
          complete: () => {
            
            this.loading = this.loading.filter(item => item !== 'updateUserPrefernceTrader');
            if (produtPortfolio.id === 0) {
              this.messageService.add({severity:'success', key: 'toast', summary:'Success', detail: 'Trader added successfully'});
            }
            else {
              this.messageService.add({severity:'success', key: 'toast', summary:'Success', detail: 'Trader updated successfully.'});
            }

            this.showUserPreferedTraderDialogForm = false;
          }
        });
    }
  }

  enableUserPreferedTraderDialog(){
    this.showUserPreferedTraderDialogForm = true;
    this.userPreferedTraderForm.reset();
    this.userPreferedTraderForm.controls['active'].setValue(true);
    this.header = 'Create Trader';
  }

  enableEditUserPreferedTraderDialog(userPreferedTrader: UserPreferenceTrader) {
    this.showUserPreferedTraderDialogForm = true;
    
     // set userPreferedTrader Form Values for update
     if (userPreferedTrader.id && userPreferedTrader) {
      this.header = 'Edit Portfolio Default';
      this.userPreferedTraderId = `${userPreferedTrader.id}`;
      this.enableUpdateUserPreferedTrader = true;
      this.userPreferedTraderForm.setValue({
        id: userPreferedTrader.id,
        active: userPreferedTrader.active,
        lastUpdatedByUserId: userPreferedTrader.lastUpdatedByUserId,
        lastUpdateTimestamp: new Date(),
        trader: userPreferedTrader.trader.fullName ?? null,
        // user: userPreferedTrader.user.clientAlias ?? null
        user: null

      })
    }
  }

  resetUserPreferedTraderDialogPostSubmit() {
    //clear the form post submission
    this.userPreferedTraderForm.reset();
    this.enableUpdateUserPreferedTrader = !this.enableUpdateUserPreferedTrader;

    this.showUserPreferedTraderDialogForm = false;
  }
}
