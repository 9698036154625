<div>
    <div class="card">
        <div class="text-900 font-bold text-xl">MOM Documentation</div>
        <div class="text-600 font-bold mb-3">MOM Version: {{githubCommitSha}} | Deployed on: {{ githubDeploymentDate | date: 'short'}}</div>
        <p class="text-600 line-height-3">Reference the below documentation regarding Metals Order Management</p>
    </div>

    <div class="flex flex-column md:flex-row gap-5">
        <div class="card mb-0 md:w-20rem">
            <div class="text-900 block font-bold mb-3">Categories</div>
            <ul class="list-none m-0 p-0">
                <li pRipple *ngFor="let item of items; let i = index" (click)="changeItem(i)" class="mb-2">
                    <a class="flex align-items-center cursor-pointer select-none p-3 transition-colors transition-duration-150 border-round" [ngClass]="{ 'bg-primary': activeIndex === i, 'hover:surface-hover': activeIndex !== i }">
                        <i [class]="item.icon" class="mr-2 text-lg"></i>
                        <span>{{ item.label }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="card flex-1">
            <p-accordion>
                <p-accordionTab *ngFor="let question of items[activeIndex].questions; let i = index" [header]="question">
                    <p class="line-height-3 m-0 p-0" [innerHTML]="items[activeIndex].answers[i]">
                    </p>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>
