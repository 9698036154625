import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, delay, retry } from 'rxjs/operators';
import { BehaviorSubject, throwError, timer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class MomApiService {
  constructor(private http: HttpClient) {}

  getData(endpoint: string) {
    const primaryUrl = `${environment.primaryApiUrl}/${endpoint}`;
    const secondaryUrl = `${environment.secondaryApiUrl}/${endpoint}`;

    // Combine primary and secondary URL calls using concat
    return this.http.get(primaryUrl)
      .pipe(
        retry({ count: 1, delay: () => timer(1000) }), // Retry once with 1 second delay
        catchError(err => {
          // If primary fails, use secondary URL and retry
          return this.http.get(secondaryUrl).pipe(
            catchError(error => {
              // Handle error from both attempts
              return throwError(error);
            })
          );
        })
      );
  }

  postData(endpoint: string, data: any) {
    const primaryUrl = `${environment.primaryApiUrl}/${endpoint}`;
    const secondaryUrl = `${environment.secondaryApiUrl}/${endpoint}`;

    return this.http.post(primaryUrl, data)
      .pipe(
        retry({ count: 1, delay: () => timer(1000) }),
        catchError(err => {
          return this.http
            .post(secondaryUrl, data)
            .pipe(
              catchError(error => {
                return throwError(error)
              })
            );
        })
      );
  }

  putData(endpoint: string, data: any) {
    const primaryUrl = `${environment.primaryApiUrl}/${endpoint}`;
    const secondaryUrl = `${environment.secondaryApiUrl}/${endpoint}`;

    return this.http.put(primaryUrl, data)
      .pipe(
        retry({ count: 1, delay: () => timer(1000) }),
        catchError(err => {
          return this.http
            .post(secondaryUrl, data)
            .pipe(
              catchError(error => {
                return throwError(error)
              }));
        })
      );
  }

  patchData(endpoint: string, data: any) {
    const primaryUrl = `${environment.primaryApiUrl}/${endpoint}`;
    const secondaryUrl = `${environment.secondaryApiUrl}/${endpoint}`;

    return this.http.patch(primaryUrl, data)
      .pipe(
        retry({ count: 1, delay: () => timer(1000) }),
        catchError(err => {
          return this.http
            .patch(secondaryUrl, data)
            .pipe(
              catchError(error => {
                return throwError(error)
              }));
        })
      );
  }

  deleteData(endpoint: string, id: any) {
    const primaryUrl = `${environment.primaryApiUrl}/${endpoint}/${id}`;
    const secondaryUrl = `${environment.secondaryApiUrl}/${endpoint}/${id}`;

    return this.http.delete(primaryUrl)
      .pipe(
        retry({ count: 1, delay: () => timer(1000) }),
        catchError(err => {
          return this.http
            .delete(secondaryUrl)
            .pipe(
              catchError(error => {
                return throwError(error)
              }));
        })
      );
  }
}