<div class="grid">
    <div class="col-12">
        <div class="card">
            <h5 class="text-center">UNA Allocations</h5>

            <div class="grid">
                <div class="col-6">
                    <p-dropdown [options]="unaClients" [(ngModel)]="selectedClient" optionLabel="name" 
                    placeholder="Select a Client" (onChange)="onClientChange($event)" [loading]="loading.includes('unaClients')"
                    [virtualScroll]="true" [virtualScrollItemSize]="38" resetFilterOnHide="true"
                    [showClear]="true" [filter]="true" filterBy="clientAlias" appendTo="body"></p-dropdown>
                </div>
                <div class="col-6" *ngIf="!loading.includes('potentialOrderFillsTrades') && selectedClient">
                    <h6>Upload UNA File</h6>
                    <p-fileUpload 
                        customUpload="true"
                        (uploadHandler)="onUpload($event)" 
                        [multiple]="false" 
                        accept=".csv" 
                        [auto]="true"
                        chooseLabel="Select File"
                        maxFileSize="1000000">
                            <ng-template pTemplate="content">
                                <ul *ngIf="uploadedFiles.length">
                                    <li *ngFor="let file of uploadedFiles">
                                        {{ file.name }} - {{ file.size }} bytes
                                    </li>
                                </ul>
                            </ng-template>
                    </p-fileUpload>
                </div>
            </div>



            <div class="grid mt-4">
                <div *ngIf="selectedClient" class="col-12 lg:col-6 md:col-6 sm:col-12">
                    <h6>Current Order Fills needing G/Us</h6>
                    <p-table [value]="potentialOrderFills" [loading]="loading.includes('potentialOrderFillsTrades')" styleClass="p-datatable-gridlines p-datatable-sm">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Order ID</th>
                                <th>Sentence</th>
                                <th>Product</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-unaAllocation>
                            <tr>
                                <td>{{unaAllocation.id}}</td>
                                <td>{{unaAllocation.orderSentence}}</td>
                                <td>{{unaAllocation.productDisplay}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">No order fills found</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>

                <div *ngIf="selectedClient" class="col-12 lg:col-6 md:col-6 sm:col-12">
                    <h6>Current Trades needing G/Us</h6>
                    <p-table [value]="potentialTrades" [loading]="loading.includes('potentialOrderFillsTrades')" styleClass="p-datatable-gridlines p-datatable-sm">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Trade ID</th>
                                <th>Sentence</th>
                                <th>Symbol</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-unaAllocation>
                            <tr>
                                <td>{{unaAllocation.orderId}}</td>
                                <td>{{unaAllocation.orderSentence}}</td>
                                <td>{{unaAllocation.symbol}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">No trades found</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

            <hr>

            <div *ngIf="selectedClient && !showStagedResults" class="grid mt-4">
                <div class="col-12">
                    <h6>Staged Result</h6>
                    <p-table> 
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Order ID</th>
                                <th>Fill ID</th>
                                <th>Symbol</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Side</th>
                                <th>Trade Date</th>
                                <th>Settle Date</th>
                                <th>Allocated</th>
                                <th>Actions</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-unaAllocation>
                            <tr>
                                <td>{{unaAllocation.orderId}}</td>
                                <td>{{unaAllocation.fillId}}</td>
                                <td>{{unaAllocation.symbol}}</td>
                                <td>{{unaAllocation.quantity}}</td>
                                <td>{{unaAllocation.price}}</td>
                                <td>{{unaAllocation.side}}</td>
                                <td>{{unaAllocation.tradeDate}}</td>
                                <td>{{unaAllocation.settleDate}}</td>
                                <td>{{unaAllocation.allocated}}</td>
                                <td>
                                    <button pButton type="button" icon="pi pi-check" class="p-button-success"></button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

        </div>
    </div>
</div>
