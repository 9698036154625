import { Component } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-documentation',
  standalone: true,
  imports: [AccordionModule, CommonModule],
  templateUrl: './documentation.component.html',
  styleUrl: './documentation.component.scss'
})
export class DocumentationComponent {
  items: any[] = [];

  activeIndex: number = 0;
  githubCommitSha: string = '';
  githubDeploymentDate: string = '';

  constructor() {}

  ngOnInit(): void {
    this.githubCommitSha = environment.githubCommitSha || 'N/A';
    this.githubDeploymentDate = environment.githubDeploymentDate || 'N/A';

      this.items = [
          {
              label: 'General',
              icon: 'pi pi-fw pi-info-circle',
              questions: ['Is there a trial period?', 'Do I need to sign up with credit card?', 'Is the subscription monthly or annual?', 'How many tiers are there?'],
              answers: ['test this and <a href="/ref-data/calendars" target="_blank">some screen</a>', 'test2', 'test3', 'test4']
          },
          {
              label: 'Mailing',
              icon: 'pi pi-fw pi-envelope',
              questions: ['How do I setup my account?', 'Is there a limit on mails to send?', 'What is my inbox size?', 'How can I add attachements?'],
              answers: ['test', 'test2', 'test3', 'test4']
          },
          {
              label: 'Support',
              icon: 'pi pi-fw pi-question-circle',
              questions: ['How can I get support?', 'What is the response time?', 'Is there a community forum?', 'Is live chat available?'],
              answers: ['test', 'test2', 'test3', 'test4']
          },
          {
              label: 'Billing',
              icon: 'pi pi-fw pi-credit-card',
              questions: ['Will I receive an invoice?', 'How to provide my billing information?', 'Is VAT included?', 'Can I receive PDF invoices?'],
              answers: ['test', 'test2', 'test3', 'test4']
          }
      ];
  }

  changeItem(i: number) {
      this.activeIndex = i;
  }
}
