import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { Title } from '@angular/platform-browser';
import { MomApiService } from '../service/mom-api.service';
import { CommonModule } from '@angular/common';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-manage-orders',
  standalone: true,
  imports: [ButtonModule, InputTextModule, TableModule, DropdownModule],
  templateUrl: './manage-orders.component.html',
  styleUrl: './manage-orders.component.scss'
})
export class ManageOrdersComponent {
  constructor(private titleService: Title, private momApiService: MomApiService, private messageService: MessageService) { }

  ngOnInit() {
    this.titleService.setTitle('Manage Orders');
  }
}
