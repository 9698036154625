import { Component, ViewChild } from '@angular/core';
import { MomApiService } from '../service/mom-api.service';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { UnaAllocation } from '../models/una-allocations';
import { InputNumberModule } from 'primeng/inputnumber';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { StagedAllocation } from '../models/staged-allocation';

@Component({
  selector: 'app-una-allocations',
  standalone: true,
  imports: [DropdownModule, ButtonModule, CommonModule, TooltipModule,
    FormsModule, FileUploadModule, TableModule, InputNumberModule, DialogModule],
  templateUrl: './una-allocations.component.html',
  styleUrl: './una-allocations.component.scss'
})
export class UnaAllocationsComponent {

  @ViewChild('fileUpload') fileUpload!: FileUpload;
  unaClients: UnaAllocation[] = [];
  loading: string[] = [];
  selectedClient: UnaAllocation;
  uploadedFiles: any[] = [];
  showStagedResults: boolean = false;
  stagedResults: StagedAllocation[] = [];
  potentialOrderFills: any[] = [];
  potentialTrades: any[] = [];
  manualAllocation: boolean = false;
  manualAllocationItem: StagedAllocation; 
  manualOrderFill: any;
  manualTrade: any;

  constructor(private momApiService: MomApiService, private messageService: MessageService) { }

  ngOnInit() {
    this.getUnaAllocationClients();
  }

  getUnaAllocationClients() {
    this.loading.push('unaClients');
    this.momApiService.getData('refdata/unaAllocations/all?activeOnly=true').subscribe({
      next: (response: any) => {
        this.unaClients = response;
      },
      error: (error) => {
        console.error('Error fetching UNA clients:', error);
        this.messageService.add({severity:'error', summary:'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred'});
        this.loading = this.loading.filter(item => item !== 'unaClients');
      },
      complete: () => {
        
        this.loading = this.loading.filter(item => item !== 'unaClients');
      }
    });
  }

  getPotentialUnaOrderTrades() {
    this.loading.push('potentialOrderFillsTrades');
    this.momApiService.getData(`order/UnaAllocations/get-potential-una-deals/${this.selectedClient.id}`).subscribe({
      next: (response: any) => {
        this.potentialOrderFills = response.orders;
        this.potentialTrades = response.trades;
      },
      error: (error) => {
        console.error('Error fetching potential UNA order fills:', error);
        this.messageService.add({severity:'error', summary:'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred' });
        this.loading = this.loading.filter(item => item !== 'potentialOrderFillsTrades');
      },
      complete: () => {
        
        this.loading = this.loading.filter(item => item !== 'potentialOrderFillsTrades');
      }
    });
  }

  analyzeUnaDealsWithAi(orders: any[], trades: any[], csvFile: File) {
    this.loading.push('analyzeUnaDeals');
    this.messageService.clear();
    this.showStagedResults = false;
    this.stagedResults = [];
    const formData = new FormData();

    formData.append('orders', JSON.stringify(orders)); // JSON data
    formData.append('trades', JSON.stringify(trades)); // JSON data
    formData.append('unaAllocation', JSON.stringify(this.selectedClient)); // JSON data
    formData.append('csvFile', csvFile, csvFile.name); // CSV file

    //order/UnaAllocations/analyze-una-deals
    //https://localhost:7025/UnaAllocations/analyze-una-deals
    //UnaAllocations/analyze-una-deals
    //return this.momApiService.postToInternalLB('UnaAllocations/analyze-una-deals', formData).subscribe({
    return this.momApiService.postData('order/UnaAllocations/analyze-una-deals', formData).subscribe({  
      next: (result: any) => {
        var response = JSON.parse(result);
        this.uploadedFiles = [];
        this.fileUpload.clear();
        if ("message" in response) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: response.message });
        }
        else {
          this.stagedResults = response;
          this.showStagedResults = true;
          this.messageService.add({ severity: 'success', key: 'toast', summary: 'Success', detail: 'Analysis completed, please verify the results below' });
          window.scrollTo(0, document.body.scrollHeight);
        }
        console.log(response);
      },
      error: (error) => {
        this.uploadedFiles = [];
        this.fileUpload.clear();
        console.log(error)
        console.log(error.error);
        this.loading = this.loading.filter(item => item !== 'analyzeUnaDeals');
        this.messageService.add({ severity: 'error', summary: 'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred' });
      },
      complete: () => {
        this.loading = this.loading.filter(item => item !== 'analyzeUnaDeals');
      }
    });
  }

  deleteStagedAllocationRecord(record: any) {
    this.stagedResults = this.stagedResults.filter(item => item !== record);
  }

  startManualTradeGiveup(unaAllocation: any) {
    console.log(unaAllocation);
    this.manualAllocationItem = {
      tradeOrderId: unaAllocation.id,
      giveupClientId: 0,
      giveupClientName: "",
      clientGiveupAccountId: 0,
      clientGiveupAccountName: "",
      allowedGiveupAccounts: unaAllocation.allowedGiveupAccounts,
      allowedGiveupBrokers: unaAllocation.allowedGiveupBrokers,
      giveupQuantity: 0,
      orderFillGroupId: 0,
      sentence: unaAllocation.tradeSentence
    }
    this.manualTrade = unaAllocation;
    this.manualOrderFill = null;
    this.manualAllocation = true;
  }

  startManualOrderFillGiveup(unaAllocation: any) {
    console.log(unaAllocation);
    this.manualAllocationItem = {
      tradeOrderId: unaAllocation.orderId,
      giveupClientId: 0,
      giveupClientName: "",
      clientGiveupAccountId: 0,
      clientGiveupAccountName: "",
      allowedGiveupAccounts: unaAllocation.allowedGiveupAccounts,
      allowedGiveupBrokers: unaAllocation.allowedGiveupBrokers,
      giveupQuantity: 0,
      orderFillGroupId: unaAllocation.orderFillGroupId,
      sentence: unaAllocation.fillSentence
    }
    this.manualOrderFill = unaAllocation;
    this.manualTrade = null;
    this.manualAllocation = true;
  }

  addManualAllocation(allocation: StagedAllocation) {
    this.stagedResults.push(allocation);
    this.showStagedResults = true;
    this.manualAllocation = false;
  }

  onUnaClientChange(event: any) {
    //This will trigger if they clear it from the dropdown so
    //Make sure it's on a selection and not a clear
    if (event.value) {
      this.messageService.clear();
      this.showStagedResults = false;
      this.stagedResults = [];
      this.getPotentialUnaOrderTrades();
    }
  }

  onUpload(event: any) {
    const files: File[] = event.files;

    for (const file of files) {
        this.analyzeUnaDealsWithAi(this.potentialOrderFills, this.potentialTrades, file);
    }
  }

  onStagedResultsGiveupAccountChange(event: any, result: any) {
    console.log(event);
    if (event.value) {
      result.clientGiveupAccountName = event.value.giveupAccount;
      result.clientGiveupAccountId = event.value.id;
    }
    else {
      //remove the result.clientGiveupAccountId and result.clientGiveupAccountName
      delete result.clientGiveupAccountId;
      delete result.clientGiveupAccountName;
    }
  }

  onStagedResultsGiveupBrokerChange(event: any, result: any) {
    console.log(event);
    console.log(result);
    result.giveupClientName = event.value.giveupBrokerName;
    result.giveupClientId = event.value.giveupBrokerId;
    console.log(result);
  }

  onManualGiveupBrokerChange(event: any) {
    this.manualAllocationItem.giveupClientId = event.value.giveupBrokerId;
    this.manualAllocationItem.giveupClientName = event.value.giveupBrokerName;
  }

  onManualGiveupAccountChange(event: any) {
    if (event.value) {
      this.manualAllocationItem.clientGiveupAccountName = event.value.giveupAccount;
      this.manualAllocationItem.clientGiveupAccountId = event.value.id;
    }
    else {
      //remove the clientGiveupAccountId and clientGiveupAccountName
      delete this.manualAllocationItem.clientGiveupAccountId;
      delete this.manualAllocationItem.clientGiveupAccountName;
    }
  }

  submitGiveupAllocations() {
    this.loading.push('submitGiveupAllocations');
    return this.momApiService.postData('order/UnaAllocations/save-giveup-allocations', this.stagedResults).subscribe({
      next: (result: any) => {
        this.stagedResults = [];
        this.showStagedResults = false;
        this.messageService.add({ severity: 'success', key: 'toast', summary: 'Success', detail: 'Giveup allocations have been saved' });
        this.getPotentialUnaOrderTrades();
      },
      error: (error) => {
        
        this.loading = this.loading.filter(item => item !== 'submitGiveupAllocations');
        this.messageService.add({ severity: 'error', summary: 'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred' });
      },
      complete: () => {
        this.loading = this.loading.filter(item => item !== 'submitGiveupAllocations');
      }
    });
  }

}
