import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MomApiService } from '../service/mom-api.service';
import { ButtonModule } from 'primeng/button';
import { MenuItem, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { FormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { TradeOrderNotesComponent } from "../shared/trade-order-notes/trade-order-notes.component";
import { Client } from '../models/client';
import { Trader } from '../models/trader';
import { Product } from '../models/product-setup';
import { UserService } from '../service/user.service';

@Component({
    selector: 'app-recent-orders-trades',
    standalone: true,
    templateUrl: './recent-orders-trades.component.html',
    styleUrl: './recent-orders-trades.component.scss',
    imports: [ButtonModule, ToastModule, MessagesModule,
        InputTextModule, CalendarModule, DropdownModule,
        AccordionModule, FormsModule, InputNumberModule,
        TableModule, CommonModule, MenuModule, DialogModule, TradeOrderNotesComponent],
})
export class RecentOrdersTradesComponent {

  clients: Client[] = [];
  traders: Trader[] = [];
  products: any[] = [];
  loading: string[] = new Array(); // Array of loading states for async operations
  showOrderTradeNotesDialog: boolean = false;
  selectedTradeOrderNote: any;
  contextMenuItems: MenuItem[] | undefined;
  onDate?: Date;
  fromDate?: Date;
  toDate?: Date;
  tradeId?: number;
  product?: string;
  client?: string;
  trader?: string;
  myLast10Orders: any[] = [];

  constructor(private titleService: Title,
    private momApiService: MomApiService,
    private messageService: MessageService,
    public sanitizer: DomSanitizer,
    public userService: UserService,
    ) { }

  ngOnInit() {
    this.titleService.setTitle('Recent Activity');
    
    this.getClients();
    this.getTraders();
    this.getProducts();

    //this.getLast10Orders();
    //this.getLast10Trades();
    //this.getSysetmLast25Orders();
    //this.getSystemLast25Trades();

    this.myLast10Orders = [{
      id: 123456,
      date: new Date(),
      category: 'Order',
      lastAction: 'Filled',
      sentence: '<span class="colSentence"><b>CORE COMMODITY</b> - SP - KMT - Max <b class="sentenceSell">Lent</b> and <b class="sentenceBuy">Sold</b>, AL-LME, 3 lots, Dec-20 / Mar-21 [Dec-20 @ MOC (Closing) 10-Aug-20, Mar-21 @ MOC (Closing) 10-Aug-20] [G/U JPM-3] (Addl Dtls: Portfolio MMAH:KMT)</span>',
      notes: ['This is a comment']
    },
    {
      id: 123456,
      date: new Date(),
      category: 'Order',
      lastAction: 'Filled',
      sentence: '<span class="colSentence"><b>CORE COMMODITY</b> - SP - KMT - Max <b class="sentenceSell">Lent</b> and <b class="sentenceBuy">Sold</b>, AL-LME, 3 lots, Dec-20 / Mar-21 [Dec-20 @ MOC (Closing) 10-Aug-20, Mar-21 @ MOC (Closing) 10-Aug-20] [G/U JPM-3] (Addl Dtls: Portfolio MMAH:KMT)</span>',
      notes: []
    }]

    this.contextMenuItems = [
      {
          label: 'Options',
          items: [
              {
                  label: 'Update',
                  icon: 'pi pi-refresh',
                  command: () => {

                  }
              },
              {
                  label: 'Delete',
                  icon: 'pi pi-times',
                  command: () => {

                  }
              }
          ]
      },
      {
          label: 'Navigate',
          items: [
              {
                  label: 'Angular',
                  icon: 'pi pi-external-link',
                  url: 'http://angular.io'
              },
              {
                  label: 'Router',
                  icon: 'pi pi-upload',
                  routerLink: '/fileupload'
              }
          ]
      }
  ];
  }

  getClients() {
    this.loading.push('clients');
    this.momApiService.getClients(false, true).subscribe({
      next: (response: Client[]) => {
        this.clients = response;
      },
      error: (error) => {
        console.error('Error fetching clients:', error);
        this.messageService.add({severity:'error', summary:'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred'});
        this.loading = this.loading.filter(item => item !== 'clients');
      },
      complete: () => {
        
        this.loading = this.loading.filter(item => item !== 'clients');
      }
    });
  }

  getProducts() {
    this.loading.push('products');
    this.momApiService.getProducts(false, true).subscribe({
      next: (response: any) => {
        this.products = response;
      },
      error: () => {
        this.messageService.add({severity:'error', summary:'Error', detail: 'Failed to load Products.'});
        this.loading = this.loading.filter(item => item !== 'products');
      },
      complete: () => {
        this.loading = this.loading.filter(item => item !== 'products');
      }
    });
  }

  getTraders() {
    this.loading.push('traders');
    this.momApiService.getTraders(false, true).subscribe({
      next: (response: Trader[]) => {
        this.traders = response;
      },
      error: (error) => {
        console.error('Error fetching traders:', error);
        this.messageService.add({severity:'error', summary:'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred'});
        this.loading = this.loading.filter(item => item !== 'traders');
      },
      complete: () => {
        
        this.loading = this.loading.filter(item => item !== 'traders');
      }
    });
  }

  onFromToDateChange() {
    this.onDate = undefined;
  }

  onOnDateChange() {
    this.toDate = undefined;
    this.fromDate = undefined;
  }

  resetFilters() {
    this.onDate = undefined;
    this.fromDate = undefined;
    this.toDate = undefined;
    this.tradeId = undefined;
    this.product = undefined;
    this.client = undefined;
    this.trader = undefined;
  }

  showOrderTradeNotes(tradeOrder: any) {
    this.selectedTradeOrderNote = tradeOrder;
    this.showOrderTradeNotesDialog = true;
  }

  closeNotesDialog() {
    this.showOrderTradeNotesDialog = false;
  }
}
