import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Product } from 'src/app/models/product-setup';
import { TableColumnType } from 'src/app/models/table-column-type';
import { RefDataFormFieldsComponent } from 'src/app/shared/ref-data-form-fields/ref-data-form-fields.component';
import { RefDataTableComponent } from 'src/app/shared/ref-data-table/ref-data-table.component';
import { UserProfile } from 'src/app/models/user-profile'
import { UserPreferenceProduct } from 'src/app/models/user-preferences-model';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { MomApiService } from 'src/app/service/mom-api.service';
import { refDataGlobalModules } from 'src/app/constants/ref-data/global-modules';

@Component({
  selector: 'app-product-user-preference',
  standalone: true,
  imports: [...refDataGlobalModules, RefDataTableComponent, RefDataFormFieldsComponent],
  templateUrl: './product-user-preference.component.html',
  styleUrl: './product-user-preference.component.scss'
})
export class ProductUserPreferenceComponent {
  showUserPreferedProductDialogForm: boolean = false;
  enableUpdateUserPreferedProduct: boolean = false;
  userPreferedProductId: string;
  header: string;
  createNewRecordLabel = "Add New Product";
  loading: string[] = new Array(); // Array of loading states for async operations

  // gentle note: where field indicates the object key name for value & header represents table header 
  // inputType indicates about the type of context rendering on UI
  columns: TableColumnType[];

  userPreferedProductForm = this.formBuilder.group({
    user : ['', Validators.required],
    product : ['', Validators.required],
    id: [0],
    active: [true],
    lastUpdatedByUserId: [1],
    lastUpdateTimestamp: [new Date()],
  });

  allProducts: Product[];
  allUsers: UserProfile[];

  // userPreferedProductData test data
  userPreferedProductData: UserPreferenceProduct[];

  constructor(private titleService: Title, private formBuilder: FormBuilder, private messageService: MessageService, private momApiService: MomApiService){}

  ngOnInit() {
    this.titleService.setTitle('Products');

    this.columns = [
      { field: 'user', header: 'User', inputType: 'dropdown', entityPropertyName:"clientAlias", dropdownContent: this.allUsers },
      { field: 'product', header: 'Product', inputType: 'dropdown', entityPropertyName:"displayName", dropdownContent: this.allProducts },
      { field: 'active', header: 'Active', inputType: 'slider', notes: 'Select edit icon to change active status' },
      { field: 'lastUpdatedByUserName', header: 'Updated By', inputType: 'text' },
      { field: 'lastUpdateTimestamp', header: 'Updated Date', inputType: 'dateTime' },
      { field: 'actionButtons', header: 'Actions', inputType: 'actionButtons', icons:["pi-pencil", "pi-trash"] },
    ];
    this.getProdutPortfolios();
    
    this.getProducts();
    // this.getPortfolioClients();
  }

  getProdutPortfolios() {
    // API call to get Products
    this.loading.push('userPreference');
    this.momApiService.getProdutPortfolios(true, false)
       .subscribe({
          next: (result: any) => {
             console.log(result);
             this.userPreferedProductData = result;
          },
          error: (error) => {
             
             this.messageService.add({ severity: 'error', summary: 'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred' });
             this.loading = this.loading.filter(item => item !== 'userPreference');
          },
          complete: () => {
             
             this.loading = this.loading.filter(item => item !== 'userPreference');
          }
       });
 }
 
//  //used to set the dropdowndata on dialog form
//  getPortfolioClients() {
//    this.momApiService.getPortfolioClients(false, true).subscribe({
//       next: (response: Client[]) => {
//         this.allUsers = response;
//         this.columns.forEach(col => {
//           if (col.field === "user") {
//             col.dropdownContent = response;
//           }
//         });
//       },
//       error: (error) => {
//         console.error('Error fetching user clients:', error);
//         this.messageService.add({severity:'error', summary:'Error', detail: 'Failed to load user clients.'});
//       }
//     });
//   }

  getProducts() {
    this.loading.push('products');
    this.momApiService.getProducts(false, false).subscribe({
      next: (response: any) => {
        this.allProducts = response;
        this.columns.forEach(col => {
          if (col.field === "product") {
            col.dropdownContent = response;
          }
        });
      },
      error: () => {
        this.messageService.add({severity:'error', summary:'Error', detail: 'Failed to load Products.'});
        this.loading = this.loading.filter(item => item !== 'products');
      },
      complete: () => {
        this.loading = this.loading.filter(item => item !== 'products');
      }
    });
  }

  addUpdateUserPreferedProducts(produtPortfolioForm: FormGroup) {
    var produtPortfolio: any;
    var isFormGroup = produtPortfolioForm instanceof FormGroup;

    const productId = this.allProducts.filter(product => product.displayName === produtPortfolioForm.controls['product'].value)[0].id;
    // const portfolioId = this.allUsers.filter(user => user.clientLongName === produtPortfolioForm.controls['user'].value)[0].id;
    
    if (isFormGroup) {
      produtPortfolio = {
        id: produtPortfolioForm.controls['id'].value ?? 0,
        active: produtPortfolioForm.controls['active'].value ?? true,
        lastUpdatedByUserId: produtPortfolioForm.controls['lastUpdatedByUserId'].value ?? 0,
        lastUpdateTimestamp: produtPortfolioForm.controls['lastUpdateTimestamp'].value ?? new Date(),
        // portfolioId: portfolioId ?? 0,
        productId: productId ?? 0,
        user: null,
        product: null
      }
    } 
    else {
      // It's already a UserGroup or some other object
      produtPortfolio = produtPortfolioForm;
    }

    if (!isFormGroup || produtPortfolioForm.valid) {
      this.loading.push('updateUserPrefernceProduct');
      this.momApiService.postData('refdata/userPreferenceProduct', [produtPortfolio])
        .subscribe({
          next: (result: any) => {
            this.getProdutPortfolios();
          },
          error: (error) => {
            
            this.messageService.add({severity:'error', summary:'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred'});
            this.loading = this.loading.filter(item => item !== 'updateUserPrefernceProduct');
          },
          complete: () => {
            
            this.loading = this.loading.filter(item => item !== 'updateUserPrefernceProduct');
            if (produtPortfolio.id === 0) {
              this.messageService.add({severity:'success', key: 'toast', summary:'Success', detail: 'Product added successfully'});
            }
            else {
              this.messageService.add({severity:'success', key: 'toast', summary:'Success', detail: 'Product updated successfully.'});
            }

            this.showUserPreferedProductDialogForm = false;
          }
        });
    }
  }

  enableUserPreferedProductDialog(){
    this.showUserPreferedProductDialogForm = true;
    this.userPreferedProductForm.reset();
    this.userPreferedProductForm.controls['active'].setValue(true);
    this.header = 'Create Product';
  }

  enableEditUserPreferedProductDialog(userPreferedProduct: UserPreferenceProduct) {
    this.showUserPreferedProductDialogForm = true;
    
     // set userPreferedProduct Form Values for update
     if (userPreferedProduct.id && userPreferedProduct) {
      this.header = 'Edit Portfolio Default';
      this.userPreferedProductId = `${userPreferedProduct.id}`;
      this.enableUpdateUserPreferedProduct = true;
      this.userPreferedProductForm.setValue({
        id: userPreferedProduct.id,
        active: userPreferedProduct.active,
        lastUpdatedByUserId: userPreferedProduct.lastUpdatedByUserId,
        lastUpdateTimestamp: new Date(),
        product: userPreferedProduct.product.displayName ?? null,
        // user: userPreferedProduct.user.clientAlias ?? null
        user: null

      })
    }
  }

  resetUserPreferedProductDialogPostSubmit() {
    //clear the form post submission
    this.userPreferedProductForm.reset();
    this.enableUpdateUserPreferedProduct = !this.enableUpdateUserPreferedProduct;

    this.showUserPreferedProductDialogForm = false;
  }
}
