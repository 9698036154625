import { Component } from '@angular/core';
import { Client } from '../models/client';
import { MomApiService } from '../service/mom-api.service';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { UnaAllocation } from '../models/una-allocations';

@Component({
  selector: 'app-una-allocations',
  standalone: true,
  imports: [DropdownModule, ButtonModule, CommonModule, FormsModule, FileUploadModule, TableModule],
  templateUrl: './una-allocations.component.html',
  styleUrl: './una-allocations.component.scss'
})
export class UnaAllocationsComponent {

  unaClients: UnaAllocation[] = [];
  loading: string[] = [];
  selectedClient: UnaAllocation;
  uploadedFiles: any[] = [];
  showStagedResults: boolean = false;
  potentialOrderFills: any[] = [];
  potentialTrades: any[] = [];

  constructor(private momApiService: MomApiService, private messageService: MessageService) { }

  ngOnInit() {
    this.getUnaAllocationClients();
  }

  getUnaAllocationClients() {
    this.loading.push('unaClients');
    this.momApiService.getData('refdata/unaAllocations/all?activeOnly=true').subscribe({
      next: (response: any) => {
        this.unaClients = response;
      },
      error: (error) => {
        console.error('Error fetching UNA clients:', error);
        this.messageService.add({severity:'error', summary:'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred'});
        this.loading = this.loading.filter(item => item !== 'unaClients');
      },
      complete: () => {
        
        this.loading = this.loading.filter(item => item !== 'unaClients');
      }
    });
  }

  getPotentialUnaOrderTrades() {
    this.loading.push('potentialOrderFillsTrades');
    this.momApiService.getData(`order/UnaAllocations/get-potential-una-deals/${this.selectedClient.id}`).subscribe({
      next: (response: any) => {
        this.potentialOrderFills = response.orders;
        this.potentialTrades = response.trades;
      },
      error: (error) => {
        console.error('Error fetching potential UNA order fills:', error);
        this.messageService.add({severity:'error', summary:'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred' });
        this.loading = this.loading.filter(item => item !== 'potentialOrderFillsTrades');
      },
      complete: () => {
        
        this.loading = this.loading.filter(item => item !== 'potentialOrderFillsTrades');
      }
    });
  }

  analyzeUnaDealsWithAi(orders: any[], trades: any[], csvFile: File) {
    const formData = new FormData();

    formData.append('orders', JSON.stringify(orders)); // JSON data
    formData.append('trades', JSON.stringify(trades)); // JSON data
    formData.append('unaAllocation', JSON.stringify(this.selectedClient)); // JSON data
    formData.append('csvFile', csvFile, csvFile.name); // CSV file

    //order/UnaAllocations/analyze-una-deals
    return this.momApiService.postData('https://localhost:7025/UnaAllocations/analyze-una-deals', formData).subscribe({
      next: (result: any) => {
        var response = JSON.parse(result);
        console.log(response);
      },
      error: (error) => {
        console.log(error)
        console.log(error.error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: typeof error?.error?.message === 'string' ? error.error.message : typeof error?.error === 'string' ? error.error : typeof error?.message === 'string' ? error.message : 'An unexpected error occurred' });
      },
      complete: () => {
        
      }
    });
  }

  onClientChange(event: any) {
    //This will trigger if they clear it from the dropdown so
    //Make sure it's on a selection and not a clear
    if (event.value) {
      this.getPotentialUnaOrderTrades();
    }
  }

  onUpload(event: any) {
    const files: File[] = event.files;

    for (const file of files) {
        // 1. Create a FormData object
        console.log(file);
        const formData = new FormData();
        formData.append('file', file, file.name); // 'file' is the parameter name expected by your server
        this.analyzeUnaDealsWithAi(this.potentialOrderFills, this.potentialTrades, file);

        // // 2. Make the HTTP request (e.g., using HttpClient)
        // this.http.post('your-upload-endpoint', formData, {
        //     // You can add headers, observe progress, etc. here
        //     // Example to observe upload progress:
        //     reportProgress: true,
        //     observe: 'events'
        // }).subscribe(event => {
        //     if (event.type === HttpEventType.UploadProgress) {
        //         const percentDone = Math.round(100 * event.loaded / event.total);
        //         console.log(`File is ${percentDone}% uploaded.`);
        //     } else if (event.type === HttpEventType.Response) {
        //         console.log('Upload complete!', event.body);
        //         this.uploadedFiles.push(file); // Add to uploadedFiles array if needed
        //     }
        // });

        // Or using fetch API
        // fetch('your-upload-endpoint', {
        //     method: 'POST',
        //     body: formData
        // })
        // .then(response => { /* handle response */ })
        // .catch(error => { /* handle error */ });

    }
}

}
