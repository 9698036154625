<div class="pb-6">
    <h4 style="text-align: center;">Manage Orders</h4>
</div>

<div class="grid align-items-center card col-12 ml-0">
    <div class="col-6 lg:col-2 md:col-4 sm:col-6">
        <p-dropdown [(ngModel)]="product" (ngModelChange)="applyGridFilter()" placeholder="Product" [showClear]="true" [options]="products"
            optionLabel="displayName" [showClear]="true" [style]="{ width: '100%' }" [filter]="true"
            filterBy="displayName" [resetFilterOnHide]="true" [virtualScroll]="true" [virtualScrollItemSize]="38"
            [loading]="loading.includes('products')" appendTo="body" >
        <i class="pi pi-info-circle"></i>
        </p-dropdown>
    </div>
    <div class="col-6 lg:col-2 md:col-4 sm:col-6">
        <p-dropdown [(ngModel)]="client" (ngModelChange)="applyGridFilter()" placeholder="Client" [options]="clients" optionLabel="clientAlias"
            [showClear]="true" [filter]="true" filterBy="clientAlias" [resetFilterOnHide]="true"
            [style]="{ width: '100%' }" [virtualScroll]="true" [virtualScrollItemSize]="38"
            [loading]="loading.includes('clients')" appendTo="body"></p-dropdown>
    </div>
    <div class="col-6 lg:col-2 md:col-4 sm:col-6">
        <p-dropdown [(ngModel)]="trader" (ngModelChange)="applyGridFilter()" [options]="traders" optionLabel="fullName" [filter]="true" filterBy="fullName"
            [resetFilterOnHide]="true" placeholder="Trader" [showClear]="true" [style]="{ width: '100%' }"
            [virtualScroll]="true" [virtualScrollItemSize]="38" [loading]="loading.includes('traders')" appendTo="body">
        </p-dropdown>
    </div>
    <div class="col-6 lg:col-6 md:col-2 sm:col-6" style="text-align: center;">
        <button pButton type="button" [label]="multiSelectLabel" class="p-button-sm mr-1" (click)="selectAllOrders()"></button>
        <button pButton type="button" label="Cancel Selected Orders" class="p-button-sm p-button-warning mr-1" (click)="cancelSelectedOrders()"></button>
        <button pButton type="button" label="Expire Selected Orders" class="p-button-sm p-button-help" severity="help" (click)="expireSelectedOrders()"></button>
    </div>
</div>

<div class="col-12 p-0">
    <div class="col-12 p-0">
        <p-table #MyLast10Orders [value]="expireOrders" [loading]="false" selectionMode="single"
            styleClass="p-datatable-gridlines p-datatable-sm">
            <ng-template pTemplate="header">
                <tr class="manage-orders-header">
                    <th [style]="{'width': '6%'}">Select Order</th>
                    <th [style]="{'width': '4%'}">Order ID </th>
                    <th [style]="{'width': '5%'}">Expiry In</th>
                    <th [style]="{'width': '5%'}">Expiry Code</th>
                    <th [style]="{'width': '7%'}">Order Date</th>
                    <th [style]="{'width': '50%'}">Order Sentence</th>
                    <th [style]="{'width': '8%'}">Client</th>
                    <th [style]="{'width': '8%'}">Product</th>
                    <th [style]="{'width': '8%'}">Trader</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
                    <tr class="manage-orders-value">
                        <td>
                            <p-checkbox [(ngModel)]="order.selectOrder" (ngModelChange)="onSelectOrderCheck()" [binary]="true" />
                        </td>
                        <td>{{ order.orderId }}</td>
                        <td>{{ order.orderExpiryDate | date:
                            (userService.userProfileSubject.value?.userProfile?.preferredDateInputFormat?.dateFormatCode ??
                            'yyyy/MM/dd') }}</td>
                        <td>{{ order.orderExpiry }}</td>
                        <td>{{ order.orderExpiryTimezone }}</td>
                        <td [innerHTML]="sanitizer.bypassSecurityTrustHtml(order.orderSentence)"></td>
                        <td>{{ order.client }}</td>
                        <td>{{ order.product }}</td>
                        <td>{{ order.trader }}</td>
                    </tr>
            </ng-template>
        </p-table>
        <div *ngIf="!expireOrders.length" class="flex align-items-center justify-content-center pi pi-info-circle" [style]="{'height': '50vh'}"> <span class="ml-2">No Orders Found</span></div>
    </div>
</div>