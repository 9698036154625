<div class="grid">
    <div class="col-12 main-table-container">
        <h4 style="text-align: center;">Traders</h4>
        <app-ref-data-table [createNewRecordLabel]="createNewRecordLabel" [tableData]="userPreferedTraderData" [cols]="columns"
            (enableNewDialogModel)="enableUserPreferedTraderDialog()" [loading]="loading.includes('userPrefernceTrader')"
            (enableEditDialogModel)="enableEditUserPreferedTraderDialog($event)"
            (activateDeactivate)="addUpdateUserPreferedTraders($event)">
        </app-ref-data-table>
    </div>
</div>

<p-dialog #DialogStep [header]="header" [(visible)]="showUserPreferedTraderDialogForm"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{ width: '50vw' }" [modal]=true>
    <ng-template pTemplate="body">
        <div class="col-12">
            <app-ref-data-form-fields [form]="userPreferedTraderForm" [cols]="columns"></app-ref-data-form-fields>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button class="p-button-danger p-button-sm m-2 p-button-raised" type="button" pButton icon="pi pi-times"
            label="CANCEL" (click)="showUserPreferedTraderDialogForm = false"
            [disabled]="loading.includes('updateUserPrefernceTrader')"></button>
        <button class="p-button-success p-button-sm m-2 p-button-raised" pButton icon="pi pi-check" label="SAVE"
            [loading]="loading.includes('updateUserPrefernceTrader')" (click)="addUpdateUserPreferedTraders(userPreferedTraderForm)"
            [disabled]="!userPreferedTraderForm.valid"></button>
    </ng-template>
</p-dialog>