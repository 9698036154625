import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserProfile } from '../models/user-profile';
import { MomApiService } from './mom-api.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root' // Ensures this service is a singleton throughout the app
})
export class UserService {
  // BehaviorSubject to store and emit the current user profile
  private userProfileSubject = new BehaviorSubject<UserProfile | null>(null);

  // Expose the user profile as an observable
  userProfile$: Observable<UserProfile | null> = this.userProfileSubject.asObservable();
  loading: string[] = [];

  constructor(private momApiService: MomApiService, private messageService: MessageService) {
    this.loadUserProfile();
  }

  // Method to set user profile information
  setUserProfile(profile: UserProfile): void {
    this.userProfileSubject.next(profile);
  }

  // Method to get the current user profile as a snapshot (useful for synchronous needs)
  getUserProfile(): UserProfile | null {
    return this.userProfileSubject.value;
  }

  // Method to clear user profile information (e.g., on logout)
  clearUserProfile(): void {
    this.userProfileSubject.next(null);
  }

  loadUserProfile(): void {
    //API call to get Product Portfolios
    this.loading.push('userProfile');
    this.momApiService.getData('refdata/userprofile/all?activeOnly=true')
      .subscribe({
        next: (result: any) => {
            this.userProfileSubject.next(result);
        },
        error: (error) => {
          // Handle error cases
          this.messageService.add({severity:'error', summary:'Error', detail: error.message});
          this.loading = this.loading.filter(item => item !== 'userProfile');
        },
        complete: () => {
          // Handle completion cases
          this.loading = this.loading.filter(item => item !== 'userProfile');
        }
      });
  }
}
